import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";

export const Partners = () => {
  const responsive = {
    0: {
      items: 1,
    },
    500: {
      items: 2,
    },
    1550: {
      items: 3,
    },
  };

  const handleDragStart = (e) => e.preventDefault();

  const images = [
    "assets/img/logos/POSTECH.png",
    "assets/img/logos/INU.png",
    //"assets/img/logos/handong.png",
    //"assets/img/logos/hankuk.gif",
    //"assets/img/logos/keimyungUniv.png",
    "assets/img/logos/IITP.png",
    //"assets/img/logos/citylabs.png",
    //"assets/img/logos/metadium.png",
    "assets/img/logos/INU.png",
  ];

  const items = images.map((image) => {
    return <img class="cover" src={image} alt="" />;
  });

  return (
    <>
      <section class="partnerpage-section" id="partner">
        <div class="text-center">
          <AliceCarousel
            mouseTracking
            infinite={1000}
            animationDuration={3000}
            disableDotsControls
            disableButtonsControls
            responsive={responsive}
            autoPlay
            items={items}
          />
        </div>
      </section>
    </>
  );
};
