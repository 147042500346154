import { useTranslation } from "react-i18next";

export const MobileAchievement = () => {
  const { t } = useTranslation();
  return (
    <section class="page-section bg-light" id="achieve">
      <div class="container ">
        <h2 class="section-heading text-uppercase">ACHIEVEMENTS</h2>
      </div>
      <div class="col-12 col-lg-3">
        <div class="achievement">
          <div class="achievementcard">
            <div class="achievement-image">
              <img
                class="rounded-circle img-fluid"
                src="assets/img/achieve/blockchain.jpg"
                alt="..."
              />
            </div>

            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="subheading">Blockchain Tech</h4>
              </div>
              <div class="timeline-body">
                <p class="text-muted">💥 {t("achivements.Blockchain.1")}</p>
                <p class="text-muted">💥 {t("achivements.Blockchain.2")}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-3">
          <div class="achievement">
            <div class="achievementcard">
              <div class="achievement-image">
                <img
                  class="rounded-circle img-fluid"
                  src="assets/img/achieve/video.png"
                  alt="..."
                />
              </div>

              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h4 class="subheading">
                    Media Streaming and Services Computing Tech
                  </h4>
                </div>
                <div class="timeline-body">
                  <p class="text-muted">💥 {t("achivements.Media.1")}</p>
                  <p class="text-muted">💥 {t("achivements.Media.2")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-3">
          <div class="achievement">
            <div class="achievementcard">
              <div class="achievement-image">
                <img
                  class="rounded-circle img-fluid"
                  src="assets/img/achieve/network.png"
                  alt="..."
                />
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h4 class="subheading">Network Tech</h4>
                </div>
                <div class="timeline-body">
                  <p class="text-muted">💥 {t("achivements.Network.1")}</p>
                  <p class="text-muted">💥 {t("achivements.Network.2")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
