import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import { Navigation } from "./components/common/nav";
import { Masthead } from "./components/common/masthead";
import { RoadMap } from "./components/common/roadMap";
import { News } from "./components/common/news";
import { Partners } from "./components/common/partner";

import WebAdvisorList from "./components/web/web_advisor";
import WebMemberList from "./components/web/web_team";
import { WebResearch } from "./components/web/web_research";
import WebServiceList from "./components/web/web_service";
import { WebAchievement } from "./components/web/web_achievement";

import MobileAdvisorList from "./components/mobile/mobile_advisor";
import MobileMemberList from "./components/mobile/mobile_team";
import { MobileResearch } from "./components/mobile/mobile_research";
import MobileServiceList from "./components/mobile/mobile_service";
import { MobileAchievement } from "./components/mobile/mobile_achievement";

import { Footer } from "./components/common/footer";
import FloatBtn from "./components/common/floatbutton";

import "./css/style.css";
import "./css/font.css";
import React, { useCallback, useState, useEffect } from "react";

import { useMediaQuery } from "react-responsive";

function App() {
  const [scrollTopBtnIsVisible, setScrollTopBtnIsVisible] = useState(false);

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const showTopBtnOnBottom = () => {
      if (window.pageYOffset > 100) {
        setScrollTopBtnIsVisible(true);
      } else {
        setScrollTopBtnIsVisible(false);
      }
    };
    window.addEventListener("scroll", showTopBtnOnBottom);
    return () => {
      window.removeEventListener("scroll", showTopBtnOnBottom);
    };
  }, []);

  const Pc = useMediaQuery({
    query: "(min-width:900px)",
  });
  const Mobile = useMediaQuery({
    query: "(max-width:899px)",
  });

  return (
    <div className="App">
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <Router>
          <Routes>
            {Pc && (
              <Route
                path="/"
                exact
                element={
                  <div>
                    <Navigation />
                    <Masthead />
                    <WebResearch />
                    <WebServiceList />
                    <WebAchievement />
                    <RoadMap />
                    <News />
                    <Partners />
                    <Footer />
                    {scrollTopBtnIsVisible && (
                      <div onClick={scrollToTop}>
                        <FloatBtn />
                      </div>
                    )}
                  </div>
                }
              />
            )}
            {Mobile && (
              <Route
                path="/"
                exact
                element={
                  <div>
                    <Navigation />
                    <Masthead />
                    <MobileResearch />
                    <MobileServiceList />
                    <MobileAchievement />
                    <RoadMap />
                    <News />
                    <Partners />
                    <Footer />
                    {scrollTopBtnIsVisible && (
                      <div onClick={scrollToTop}>
                        <FloatBtn />
                      </div>
                    )}
                  </div>
                }
              />
            )}

            {/* {Pc && (
              <Route
                path="/team"
                exact
                element={
                  <div>
                    <Navigation />
                    <WebMemberList />
                    <WebAdvisorList />
                  </div>
                }
              />
            )}
            {Mobile && (
              <Route
                path="/team"
                exact
                element={
                  <div>
                    <Navigation />
                    <MobileMemberList />
                    <MobileAdvisorList />
                  </div>
                }
              />
            )} */}
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
