import { motion } from "framer-motion";
import { BounceScroll } from "../animation/bounceScroll";
import { useTranslation } from "react-i18next";

export const RoadMap = () => {
  const { t } = useTranslation();
  return (
    <section class="page-section" id="about">
      <div class="grid-contain">
        <div class="text-center">
          <h2 class=" section-heading text-start">ROADMAP</h2>
          <br></br>
        </div>
        <motion.ul class="timeline">
          <motion.li
            initial={BounceScroll.offscreen}
            whileInView={BounceScroll.onscreen}
            viewport={{ once: true, amount: 0.8 }}
          >
            <div class="timeline-image">
              <img
                class="rounded-circle img-fluid"
                src="assets/img/about/1.jpg"
                alt="..."
              />
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4>2022. 4Q</h4>
                <h4 class="subheading"></h4>
              </div>
              <div class="timeline-body">
                <p class="text-muted">
                  <mark> {t("loadmap.2022/4Q.1")}</mark>
                </p>
                <p class="text-muted">
                  <mark>{t("loadmap.2022/4Q.2")}</mark>
                </p>
                <p class="text-muted">
                  <mark>{t("loadmap.2022/4Q.3")}</mark>
                </p>
              </div>
            </div>
          </motion.li>
          <motion.li
            class="timeline-inverted"
            initial={BounceScroll.offscreen}
            whileInView={BounceScroll.onscreen}
            viewport={{ once: true, amount: 0.8 }}
          >
            <div class="timeline-image">
              <img
                class="rounded-circle img-fluid"
                src="assets/img/about/2.jpg"
                alt="..."
              />
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4>2023. 1Q</h4>
              </div>
              <div class="timeline-body">
                <p class="text-muted">
                  <mark>{t("loadmap.2023/1Q.1")}</mark>
                </p>
                <p class="text-muted">
                  <mark>{t("loadmap.2023/1Q.2")}</mark>
                </p>
                <p class="text-muted">
                  <mark>{t("loadmap.2023/1Q.3")}</mark>
                </p>
                <p class="text-muted">
                  <mark>{t("loadmap.2023/1Q.4")}</mark>
                </p>
              </div>
            </div>
          </motion.li>
          <motion.li
            initial={BounceScroll.offscreen}
            whileInView={BounceScroll.onscreen}
            viewport={{ once: true, amount: 0.8 }}
          >
            <div class="timeline-image">
              <img
                class="rounded-circle img-fluid"
                src="assets/img/about/3.jpg"
                alt="..."
              />
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="subheading">2023. 2Q</h4>
              </div>
              <div class="timeline-body">
                <p class="text-muted">
                  <mark>{t("loadmap.2023/2Q.1")}</mark>
                </p>
                <p class="text-muted">
                  <mark>{t("loadmap.2023/2Q.2")}</mark>
                </p>
                <p class="text-muted">
                  <mark>{t("loadmap.2023/2Q.3")}</mark>
                </p>
              </div>
            </div>
          </motion.li>
          <motion.li
            class="timeline-inverted"
            initial={BounceScroll.offscreen}
            whileInView={BounceScroll.onscreen}
            viewport={{ once: true, amount: 0.8 }}
          >
            <div class="timeline-image">
              <img
                class="rounded-circle img-fluid"
                src="assets/img/about/4.jpg"
                alt="..."
              />
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="subheading">2023. 3Q</h4>
              </div>
              <div class="timeline-body">
                <p class="text-muted">
                  <mark>{t("loadmap.2023/3Q.1")}</mark>
                </p>
              </div>
            </div>
          </motion.li>
        </motion.ul>
      </div>
    </section>
  );
};
