export const button = {
  rest: { scale: 1 },
  hover: { scale: 1.1 },
  pressed: { scale: 0.95 },
};
export const drc = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

/*export const arrow = {
    rest: { rotate: 0 },
    hover: { rotate: 360, transition: { duration: 0.4 } },
  };*/
