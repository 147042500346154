import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faFile, faPlay } from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { platforms } from "../common/serviceList";
import { useTranslation } from "react-i18next";
import i18n from "../../lang/i18n";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { serviceVariant } from "../animation/bounceScroll"

class WebServiceList extends React.Component {
  render() {
    const service = platforms.map((service) => (
      <Platform
      id={service.id}
      name={service.name}
      descript1_en={service.descript1_en}
      descript1_ko={service.descript1_ko}
      descript_en={service.descript_en}
      descript_ko={service.descript_ko}
      button={service.button}
      logoimage={service.logoimage}
      newsimage={service.newsimage}
      youtubeurl={service.youtube}
      engdocumenturl={service.engdocument}
      kordocumenturl={service.kordocument}
      url={service.url}
      />
    ));
    return <div className="container">{service}</div>;
  }
}

function Platform(props) {
  const { t } = useTranslation()
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <section class="page-section" id={props.name}>
      <motion.div class="no">
        <div class="row">
          <div class="col-lg-7">
            <h1 class="row">
              <motion.div class="col-lg-7">
                <span id={"service-name"}>
                  {props.name}
                </span>
                <img class="logoicon-fluid" src={props.logoimage} alt="..." />
              </motion.div>
            </h1>
            <br></br>
            {i18n.language==="ko"?
                <span id={"service-sub"}>{props.descript1_ko}</span>
                :
                <span id={"service-sub"}>{props.descript1_en}</span>
                }

            <br></br>
            <br></br>
            {i18n.language==="ko"?
                <p id="service-desc">{props.descript_ko}</p>
                :
                <p id="service-desc">{props.descript_en}</p>
                }
            <br></br><br></br>
            <motion.a
            whileHover={{ scale: [null, 1.2, 1.1] }}
              class="buttontitle" href={props.url} target="_blank">
              {t('Watchnow')} <FontAwesomeIcon icon={faPlay} size="x" />
            </motion.a>
            <br></br>
            <motion.a 
            whileHover={{ scale: [null, 1.2, 1.1] }}
            class="buttontitle" href={props.engdocumenturl} target="_blank">
              {t('Download')} <FontAwesomeIcon icon={faFile} size="x" />
            </motion.a>
            <motion.a 
            whileHover={{ scale: [null, 1.6, 1.5] }}
             class="buttontitlereverse" href={props.youtubeurl} target="_blank">
              {t('Demo')} <FontAwesomeIcon icon={faYoutube} size="x" />
            </motion.a>
          </div>

          <div class="col-lg-auto ">
            <div class="newsimagecenter">
              <a class="" href={props.engdocumenturl} target="_blank">
                <motion.img
                                  ref={ref}
                                  variants={serviceVariant}
                                  initial="hidden"
                                  animate={control}
                                  transition={{ duration: 0.5 }}
                 class=" newsimage-fluid" src={props.newsimage} alt="..." />
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
}

export default WebServiceList;
