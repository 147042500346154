export const BounceScroll = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 0, //50
    rotate: 0, //-10
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const BounceScrollR = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 0, //50
    rotate: 10, //-10
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const serviceVariant = {
  visible: { opacity: 1, scale: 1 },
  hidden: { opacity: 0, scale: 0.9 }
};

export const reserchTrans = {
  default: {
    duration: 0.3,
    ease: [0, 0.71, 0.2, 1.01]
  },
  scale: {
    type: "spring",
    damping: 3,
    stiffness: 100,
    restDelta: 0.001
  }
}
export const serviceTrans = {
  default: {
    duration: 0.3,
    ease: [0, 0.71, 0.2, 1.01]
  },
  scale: {
    type: "spring",
    damping: 5,
    stiffness: 100,
    restDelta: 0.001
  }
}

