import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPlay } from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import {platforms} from "../common/serviceList";
import { useTranslation } from "react-i18next";
import i18n from "../../lang/i18n";

class MobileServiceList extends React.Component {
  render() {
    const service = platforms.map((service) => (
      <Platform
      id={service.id}
      name={service.name}
      descript1_en={service.descript1_en}
      descript1_ko={service.descript1_ko}
      descript_en={service.descript_en}
      descript_ko={service.descript_ko}
      button={service.button}
      logoimage={service.logoimage}
      newsimage={service.newsimage}
      youtubeurl={service.youtube}
      engdocumenturl={service.engdocument}
      kordocumenturl={service.kordocument}
      url={service.url}
      />
    ));
    return <div className="container">{service}</div>;
  }
}

function Platform(props) {
  const { t } = useTranslation()
    return (
      <section class="page-section" id={props.name}>
        <div class="row">
          <div class="col-lg-7">
            <div class="p-4">
              <div class="row">
                  <div class="col-lg-12">
                    <span id="mobileservice-name">
                      {props.name}
                    </span>
                    <img class="logoicon-fluid" src={props.logoimage} alt="..." />
                  </div>
                </div>

                <br></br>
                <br></br>
                {i18n.language==="ko"?
                <p id="service-desc">{props.descript_ko}</p>
                :
                <p id="service-desc">{props.descript_en}</p>
                }
                <br></br>
                <div class="row-cols-9">
                <a class="mobilebuttontitlereverse" href={props.url} target="_blank">
                {t('Watchnow')} <FontAwesomeIcon icon={faPlay} size="x"  />
                </a>
                <br></br>
                <a class="mobilebuttontitlereverse" href={props.engdocumenturl} target="_blank">
                {t('Download')} <FontAwesomeIcon icon={faFile} size="x" />
                </a>
                <a class="mobilebuttontitlereverse" href={props.youtubeurl} target="_blank"> 
                {t('Demo')} <FontAwesomeIcon icon={faYoutube} size="x" />
                </a>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
            <div class="p-4">
              <div class="mobilenewsimagecenter">
              <a class="" href={props.engdocumenturl} target="_blank">
                <img class="mobilenewsimage-fluid" src={props.newsimage} alt="..." />
              </a>
              </div>
            </div>
          </div>
          </div>

      </section>
    );
}

export default MobileServiceList;
