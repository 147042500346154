import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faDownload,
  faHouseMedical,
} from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { watchdog } from "../common/watchdogInfo";
import { useTranslation } from "react-i18next";

import * as React from "react";
import { styled } from "@mui/system";

const Card1 = styled("Card")({
  background: "#fff",
  height: "26rem",
  boxShadow: "0 12px 24px -12px rgb(237,128,8)",
  borderRadius: "30px",
  padding: "20px 20px",
  marginBottom: "1.5rem",
  display: "grid",
  justifyItems: "center",
});

export const MobileResearch = () => {
  const { t } = useTranslation();
  return (
    <section class="page-section bg-light" id="services">
      <div class="container">
        <div class="row">
          <div class="col-5">
            <h2 class="section-heading">About WATCHDOG </h2>
          </div>
        </div>
        <h3 class="section-subheading text-black">{t("research.Watchdog")}</h3>
        <div class="mobilerow">
          <a
            href={watchdog[0].engdocument}
            target="_blank"
            class="mobilebuttontitlereverse"
          >
            {t("Download")} <FontAwesomeIcon icon={faDownload} size="x" />
          </a>
        </div>
        <a
          href={watchdog[0].youtube}
          target="_blank"
          class="mobilebuttontitlereverse"
        >
          {t("Demo")} <FontAwesomeIcon icon={faYoutube} size="x" />
        </a>
      </div>
      <br></br>
      <h2 class="text-center section-heading">WATCHDOG {"\n"} DAPPs</h2>
      <h3 class="section-subheading text-muted"></h3>
      <div class="text-center">
        <div class="col-12 col-lg-3">
          <div class="p-1">
            <Card1>
              <FontAwesomeIcon icon={faCirclePlay} size="5x" color="orange" />
              <h5 class="card-title">RETRIEVER</h5>
              <p class="card-text">{t("research.Retriever")}</p>
              <a href="#RETRIEVER" class="researchbutton">
                {t("Learnmore")}
              </a>
            </Card1>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="p-1">
            <Card1>
              <FontAwesomeIcon icon={faComment} size="5x" color="orange" />
              <h5 class="card-title">FOOTPRINT</h5>
              <p class="card-text">{t("research.FOOTPRINT")}</p>
              <a href="#FOOTPRINT" class="researchbutton">
                {t("Learnmore")}
              </a>
            </Card1>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="p-1">
            <Card1>
              <FontAwesomeIcon icon={faHouseMedical} size="5x" color="orange" />
              <h5 class="card-title">HealthBit</h5>
              <p class="card-text">{t("research.HealthBit")}</p>
              <a href="#HealthBit" class="researchbutton">
                {t("Learnmore")}
              </a>
            </Card1>
          </div>
        </div>
      </div>
    </section>
  );
};
