import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faPlay } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { button,drc } from "../animation/clickImage";
import { Btn } from "../animation/button/buttonRun";
import { useTranslation } from "react-i18next";


import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


export const Masthead = () => {
  const { t } = useTranslation()
  useEffect(() => {
    AOS.init();
  }); /*없애면 에러뜸*/
  return (

    <header class="masthead">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="masthead-heading">{t('head.1')}</div>
            <div class="masthead-highlightheading">{t('head.2')}</div>
            <br></br>
            {/* <a href={url} class="buttontitle" target="_blank">
              Watch Now 
  </a>*/}
            <Btn/>
          
          </div>
          <div class="col-lg-5">
            
            <motion.img class="img-fluid" src="assets/img/icon/puppy3.gif" alt="..."

              drag
              dragConstraints={drc}
              variants={button}
              initial="rest"
              whileHover="hover"
              whileTap="pressed" />

          </div>
        </div>
    
        
        
      </div>
    </header>
  );
};
