import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { serviceVariant } from "../animation/bounceScroll";

export const News = () => {
  const { t } = useTranslation();

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <section class="newspage-section bg-light" id="news">
      <div class="newscontainer">
        <h2 class="section-heading text-uppercase">Last Article</h2>
      </div>

      <motion.div class="news">
        <div class="newscard">
          <div class="news-image">
            <img
              class="newsarticleimage-fluid"
              src="assets/img/news/newsArticle2.png"
              alt="..."
            />
          </div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <motion.h4
                ref={ref}
                variants={serviceVariant}
                initial="hidden"
                animate={control}
                class="subheading"
              >
                {" "}
                {t("article")}
              </motion.h4>
            </div>
            <div class="timeline-body">
              <div class="row">
                <div class="col-lg-auto">
                  <p class="text-muted">2022-11-15</p>
                </div>
                <div class="col-lg-auto">
                  <motion.a
                    whileHover={{ scale: [null, 1.3, 1.2] }}
                    href="https://blog.watchdognet.com/2022-11-14-retriever/"
                    target="_blank"
                    class="buttontitlereverse"
                  >
                    {t("Read")}{" "}
                    <FontAwesomeIcon icon={faArrowCircleRight} size="x" />
                  </motion.a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};
