import React,{useState} from "react";
import Advisor from "../common/advisorList";
import { faArrowCircleDown, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next"

export default function WebAdvisorList()  {
  const { t } = useTranslation()
  
    const [visible,setVisible] = useState(false);

    return (
      <section class="smallpage-section" id="team">
        <div class="advisorcontainer">
        <h2 class="section-heading text-uppercase">ADVISOR </h2>
              <Grid container spacing={1}>
                {Advisor.advisors.map((advisor, index) => (
                <Grid item xs={3}>
                    <Advisors
                      affiliate={advisor.affiliate}
                      name={advisor.name}
                      img={advisor.image}
                      position={advisor.position}
                      development={advisor.development}
                      site={advisor.url}
                      git={advisor.git}
                    />
                  </Grid>
                ))}
              </Grid>
          </div>
      </section>
    );
  }

class Advisors extends React.Component {
  render() {
    return (
      <div class="team-member">
        <img class="webteamimage rounded-circle" src={this.props.img} />
        <h4 class=" ">{this.props.name}</h4>
        <br></br>
        <p class="h6">{this.props.position}</p>
      </div>
    );
  }
}

