export const watchdog = [
  {
    id: 1,
    name: "WATCHDOG",
    descript:
      "Blockchain which can be operated with any blockchain system and existing database management system to improve simultaneously scalability and decentralization.",
    logoimage: "assets/img/icon/dog-puppy-icon.svg",
    newsimage: "assets/img/news/retriever.png",
    youtube:
      "https://www.retriever.live/player?=bafybeid7iezw7fkwjsf2i7g3zkhkhct2msnd72xkibusp5glvhufr5xpju",
    engdocument: "https://blog.watchdognet.com/?tags=wdn",
    kodocument: "https://blog.watchdognet.com/kor/?tags=wdn",
    url: "https://www.retriever.live/player?=bafybeieenrq775f27wvzymdqemjuihwupqzvlt262op4uc4uyvxctjrxee",
  },
];
