export const platforms = [
  {
    id: 1,
    name: "RETRIEVER",
    descript1_en: "Web 3.0 Streaming Services Platform",
    descript1_ko: "웹 3.0 스트리밍 서비스 플랫폼",
    descript_en:
      "Streaming service platform that seamlessly provides high-quality video while preventing copyright infringement in a decentralized system environment",
    descript_ko:
      "분산된 시스템 환경에서 저작권 침해를 방지하면서 고품질 비디오를 원활하게 제공하는 스트리밍 서비스 플랫폼",
    button: "PLAY RETRIVER",
    logoimage: "assets/img/icon/retriever.svg",
    newsimage: "assets/img/news/retriever.png",
    youtube:
      "https://www.retriever.live/player?=bafybeig24374sjxfmjlklcdyj4lalxinu35xag7bbyjueievvnkufecuca",
    engdocument: "https://blog.watchdognet.com/?tags=retriever",
    kordocument: "https://blog.watchdognet.com/kor/?tags=retriever",
    url: "https://www.retriever.live/",
  },
  {
    id: 2,
    name: "FOOTPRINT",
    descript1_en: "Secure P2P Chat",
    descript1_ko: "보안 P2P 채팅",
    descript_en:
      "Fully decentralized P2P messenger which ensures user privacy and prevents the record from being tampered",
    descript_ko:
      "사용자의 개인 정보를 보장하고 기록이 변조되는 것을 방지하는 완전히 분산된 P2P 메신저",
    button: "DOWNLOAD FOOTPRINT",
    logoimage: "assets/img/icon/hush.svg",
    newsimage: "assets/img/news/hush.png",
    youtube:
      "https://www.retriever.live/player?=bafybeif4ccpeora3jx63dh72355tw6ixgsezen6iqudyhltxm7kvt2r6pq",
    engdocument: "https://blog.watchdognet.com/?tags=hush",
    kodocument: "https://blog.watchdognet.com/kor/?tags=hush",
    url: "",
  },
  {
    id: 3,
    name: "HealthBit",
    descript1_en: "Web 3.0 Medical AI Service",
    descript1_ko: "웹 3.0 의료 AI 서비스",
    descript_en:
      "AI/Blockchain-based medical service that predicts a user's disease based on an AI model and protects personal health records by encrypting them during the entire process from data transmission to AI model learning",
    descript_ko:
      "데이터 전송부터 AI 모델 학습까지 전 과정에서 AI 모델을 기반으로 사용자의 질병을 예측하고 개인 건강기록을 암호화해 보호하는 AI/블록체인 기반 의료서비스",
    button: "PLAY HealthBit",
    logoimage: "assets/img/icon/Healer.svg",
    newsimage: "assets/img/news/healer.png",
    youtube:
      "https://retriever.live/player?=bafybeidvidg3ouyfc2nhdcypty6wsxxov72omjx4lsqzqda3rqabj754um",
    engdocument: "https://blog.watchdognet.com/kor/?tags=healer",
    kodocument: "https://blog.watchdognet.com/kor/?tags=healer",
    url: "https://healthBit.kr",
  },
];
