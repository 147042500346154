import React, { useState } from "react";
import Members from "../common/memberList";
import {
  faArrowCircleDown,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

export default function WebMemberList() {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  return (
    <section class="smallpage-section" id="team">
      <div class="teamcontainer">
        <Grid container spacing={1}>
          {Members.members.map((member, index) => (
            <Grid item xs={3}>
              <Member
                affiliate={member.affiliate}
                name={member.name}
                img={member.image}
                position={member.position}
                development={member.development}
                site={member.url}
                git={member.git}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
}

class Member extends React.Component {
  render() {
    return (
      <div class="team-member">
        <img class="webteamimage rounded-circle" src={this.props.img} />
        <h4 class=" ">{this.props.name}</h4>
        <br></br>
        <p class="h6">{this.props.position}</p>
      </div>
    );
  }
}
