import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { button, drc } from "../animation/clickImage";
import { serviceVariant, reserchTrans } from "../animation/bounceScroll";
import { watchdog } from "../common/watchdogInfo";
import { useTranslation } from "react-i18next";

import {
  faCirclePlay,
  faDownload,
  faHouseMedical,
} from "@fortawesome/free-solid-svg-icons";

import { faComment } from "@fortawesome/free-regular-svg-icons";

import * as React from "react";
import { styled } from "@mui/system";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

const Card1 = styled("Card")({
  background: "#fff",
  height: "35rem",
  boxShadow: "0 12px 24px -12px rgb(237,128,8)",
  borderRadius: "30px",
  padding: "20px 20px",
  display: "grid",
  justifyItems: "center",
});

export const WebResearch = () => {
  const { t } = useTranslation();

  const control = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <section class="page-section bg-light" id="services">
      <div class="container">
        <div class="row">
          <div class="col-8">
            <div class="p-2">
              <h2 class="section-heading">About WATCHDOG </h2>
              <h3 class="section-subheading text-black">
                {t("research.Watchdog")}
              </h3>
              <motion.a
                whileHover={{ scale: [null, 1.2, 1.1] }}
                class="buttontitle"
                href={watchdog[0].engdocument}
                target="_blank"
              >
                {t("Download")} <FontAwesomeIcon icon={faDownload} size="x" />
              </motion.a>
              <motion.a
                whileHover={{ scale: [null, 1.6, 1.5] }}
                class="buttontitlereverse"
                href={watchdog[0].youtube}
                target="_blank"
              >
                {t("Demo")} <FontAwesomeIcon icon={faYoutube} size="x" />
              </motion.a>
            </div>
          </div>
          <div class="col-4">
            <div class="p-3">
              <motion.img
                class="img-fluid2"
                src={"assets/img/icon/dog-puppy-icon.svg"}
                alt="..."
                drag
                dragConstraints={drc}
                variants={button}
                initial="rest"
                whileHover="hover"
                whileTap="pressed"
              />
            </div>
          </div>
        </div>
      </div>
      <br></br>

      <div class="container">
        <div class="text-center">
          <h2 class="section-heading">WATCHDOG DAPPs</h2>
        </div>
        <div class="row text-center">
          <div class="col-4 col-lg-4">
            <motion.div class="p-1">
              <Card1>
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  size="6x"
                  color="rgb(237,128,8)"
                />
                <h5 class="card-title">RETRIEVER</h5>
                <motion.p
                  class="card-text"
                  ref={ref}
                  variants={serviceVariant}
                  initial="hidden"
                  animate={control}
                  transition={reserchTrans}
                >
                  {t("research.Retriever")}
                </motion.p>
                <motion.a
                  whileHover={{ scale: [null, 1.4, 1.3] }}
                  href="#RETRIEVER"
                  class="researchbutton"
                >
                  {t("Learnmore")}
                </motion.a>
              </Card1>
            </motion.div>
          </div>
          <div class="col-4 col-lg-4">
            <motion.div class="p-1">
              <Card1>
                <FontAwesomeIcon
                  icon={faComment}
                  size="6x"
                  color="rgb(237,128,8)"
                />
                <h5 class="card-title">FOOTPRINT</h5>
                <motion.p
                  class="card-text"
                  ref={ref}
                  variants={serviceVariant}
                  initial="hidden"
                  animate={control}
                  transition={reserchTrans}
                >
                  {t("research.FOOTPRINT")}
                </motion.p>
                <motion.a
                  whileHover={{ scale: [null, 1.4, 1.3] }}
                  href="#FOOTPRINT"
                  class="researchbutton"
                >
                  {t("Learnmore")}
                </motion.a>
              </Card1>
            </motion.div>
          </div>
          <div class="col-4 col-lg-4">
            <motion.div class="p-1">
              <Card1>
                <FontAwesomeIcon
                  icon={faHouseMedical}
                  size="6x"
                  color="rgb(237,128,8)"
                />
                <h5 class="card-title">HealthBit</h5>
                <motion.p
                  class="card-text"
                  ref={ref}
                  variants={serviceVariant}
                  initial="hidden"
                  animate={control}
                  transition={reserchTrans}
                >
                  {t("research.HealthBit")}
                </motion.p>
                <motion.a
                  whileHover={{ scale: [null, 1.4, 1.3] }}
                  href="#HealthBit"
                  class="researchbutton"
                >
                  {t("Learnmore")}
                </motion.a>
              </Card1>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};
