import React, { useState } from "react";
import Members from "../common/memberList";
import {
  faArrowCircleDown,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

export default function MobileMemberList() {
  const { t } = useTranslation();
  return (
    <section class="smallpage-section" id="team">
      <div class="mobileteamcontainer ">
        {Members.members.map((member, index) => (
          <Grid item xs={7} key={index}>
            <Member
              affiliate={member.affiliate}
              name={member.name}
              img={member.image}
              position={member.position}
              development={member.development}
              site={member.url}
              git={member.git}
            />
          </Grid>
        ))}
      </div>
    </section>
  );
}

class Member extends React.Component {
  render() {
    return (
      <div class="team-member">
        <img class="mobileteamimage rounded-circle" src={this.props.img} />
        <h4 class="text-black">{this.props.name}</h4>
        <br></br>
        <p class="h5 text-dark">{this.props.position}</p>
      </div>
    );
  }
}
