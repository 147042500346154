import styled from "styled-components";

const FloatBtn=()=>{
    return(
        <FloatingBtn>
        🔝
        </FloatingBtn>
    )
}

export default FloatBtn;

const FloatingBtn = styled.div`
  position: fixed; //포인트!
  line-height: 63px;
  bottom: 20px; //위치
  right: 30px;  //위치
  width: 50px;  
  height: 50px;
  border-radius: 10%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;