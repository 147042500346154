import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langEn from './lang.en.json';
import langKo from './lang.ko.json';

const resource = {
    en: {
        translation: langEn
    },
    kr: {
        translation: langKo
    }
}

i18n.use(initReactI18next).init({
    resources: resource,
    lng: "en",
    fallbackLng: "kr",
    debug: true,
    keySeparator: ".",
    interpolation: { escapeValue: false },
  });

export default i18n;