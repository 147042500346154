import React,{useState} from "react";
import Advisor from "../common/advisorList";
import { faArrowCircleDown, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

export default function MobileAdvisorList() {
  const { t } = useTranslation()
    return (
      <section class="smallpage-section" id="team">
        <div class="mobileadvisorcontainer ">
            <h2 class="section-heading text-center ">ADVISOR</h2>
                {Advisor.advisors.map((advisor, index) => (
                  <Grid item xs={7} key={index}>
                      <Advisors
                        affiliate={advisor.affiliate}
                        name={advisor.name}
                        img={advisor.image}
                        position={advisor.position}
                        development={advisor.development}
                        site={advisor.url}
                        git={advisor.git}
                      />
                  </Grid>
                ))}
          </div>
      </section>
    );
  }

class Advisors extends React.Component {
  render() {
    return (
      <div class="team-member">
        <img class="mobileteamimage rounded-circle" src={this.props.img} />
        <h4 class="text-black">{this.props.name}</h4>
        <br></br>
        <p class="h5 text-dark">{this.props.position}</p>
      </div>
    );
  }
}


