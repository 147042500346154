export const Footer = () => {
  return (
    <footer class="footer py-4">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 text-lg-start">
            Copyright &copy; 2023 Incheon National University (INU) All Rights
            Reserved.
          </div>
          <br></br>
          <br></br>
          <div class="col-lg-7 text-lg-end">
            Distributed Systems and Web Engineering (Dweb)
          </div>
        </div>
      </div>
    </footer>
  );
};
