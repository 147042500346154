import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../lang/i18n";
import { useNavigate } from "react-router";

export const Navigation = () => {
  const navigate = new useNavigate();
  const { t } = useTranslation();
  return (
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
      <div class="container">
        <a class="navbar-brand" href="#page-top">
          <img src="assets/img/icon/dog-puppy-icon.svg" alt="..." /> WATCHDOG
          NETWORK
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          menu
          <i class="fas fa-bars ms-1"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
            <li class="nav-item">
              <a
                class="nav-link"
                onClick={() => navigate("/")}
                href="#services"
              >
                DAPPS
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" onClick={() => navigate("/")} href="#about">
                ROADMAP
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" onClick={() => navigate("/")} href="#achieve">
                ACHIVEMENTS
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" onClick={() => navigate("/")} href="#news">
                NEWS
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" onClick={() => navigate("/team")}>
                TEAM
              </a>
            </li>
            <li class="nav-item">
              {i18n.language === "ko" ? (
                <a
                  class="nav-link"
                  onClick={() => {
                    i18n.changeLanguage("en");
                  }}
                >
                  ENG
                </a>
              ) : (
                <a
                  class="nav-link"
                  onClick={() => {
                    i18n.changeLanguage("ko");
                  }}
                >
                  KOR
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
